.splash-root {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: #c5c5c5;
}

.splash-logo {
    max-width: 10vw;
    align-self: center
}