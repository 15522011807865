.error-screen-container {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.error-screen-content {
    margin-top: 20px;
    padding: 20px 40px;
    background-color: #fff;
    border-radius: 6px;
}

.error-screen-section {
    margin-bottom: 15px;
}

.error-screen-root h1 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.error-screen-root p {
    margin-top: 0;
}

.error-screen-root code {
    font-family: 'Cascadia Code', 'Consolas', 'Courier New', 'monospace';
    padding: .2em .4em;
    margin: 0;
    font-size: 85%;
    background-color: #afb8c133;
    color: #24292f;
    border-radius: 6px;
    margin-bottom: 10px;
}

.error-screen-footer{
    text-align: center;
    color: #fff;
}

h1{
    font-size: 16pt;
}

.error-screen-txt{
    margin-bottom: 2pt;
}