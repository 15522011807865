body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: #222222;
}

#root {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.page-root {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}