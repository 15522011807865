.not-found-page-root{
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    color: #fff;
    text-align: center;
}

.not-found-page-container {
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
}

.not-found-page-content {
    margin-top: 20px;
    padding: 20px 40px;
}

.not-found-page-section {
    margin-bottom: 15px;
}

.not-found-page-root h1 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 42px;
}

.not-found-page-footer{
    padding-bottom: 20px;
}